.chat {
  .message-input {
    textarea {
      min-height: 6rem;
      resize: vertical;
    }
  }
  &-container {
    &--video {
      max-height: 380px;

      #incoming {
        width:100%;
      }
      #outcoming {
        position: relative;
        width:100px;
        height:100px;
        top:-115px;
        left:0;
      }
    }
  }

  .messages-container {
    overflow-y: scroll;
    height: 400px;

    @media (min-width: 375px) {
      height: 40vh;
    }
  }
}
