// Variable overrides for DefaultTheme
$primary-color: #0C64F5;
$theme-colors: (
  'primary': $primary-color,
);
$link-hover-color: $primary-color;

$btn-border-radius: 18px;
$btn-padding-x: 10px;

@import '../../../../../../node_modules/bootstrap/scss/bootstrap';
