.doctors-modal {
    padding: 15px;
    position: absolute;
    max-height: 600px;
    border: 1px solid #cacaca;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background: #f8f9fa;
    overflow: hidden;
    top: 25%;
    width: 90vw;
    z-index: 999999999;
}
@media (min-width:480px) {
    .doctors-modal {
        width: 90vw;
        top: 35%;
    }
}

@media (min-width:600px) {
    .doctors-modal {
        width: 60vw;
        top: 20%;
    }
}

@media (min-width:900px) {
    .doctors-modal {
        width: 60vw;
        top: 20%;
    }
}
.modal-wrapper {
    width: 100vw;
    background: rgba(0,0,0, .15);
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
}
