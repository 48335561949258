@import '../node_modules/bootstrap/scss/bootstrap';

body {
  background-color: #f8f8f8!important;
}

.b-confirm {
  border-radius: 8px;
}

.form-control:focus {
  color: $secondary;
  background-color: #fff;
  border-color: inherit;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.__dark-violet {
  background-color: #CBC3E3 !important;
  color: white !important;
}

.__violet {
  background-color: rgba($pink, 0.6) !important;
  color: white !important;
}

.__green {
  background-color: $green !important;
  color: white !important;
}

.__color-green {
  color: $green;
}


.__light-warning {
  background-color: rgba($warning, 0.2) !important;
}

.__light-danger {
  background-color: rgba($red, 0.1) !important;
}

.__light-success {
  background-color: rgba($green, 0.1) !important;
}

.__success {
  background-color: rgba($green, 0.3) !important;
}

.__text-success {
  color: rgba($green, 0.5) !important;
}


.__red {
  background-color: $red !important;
  color: white !important;
}

.__color-red {
  color: $red;
}

.__admin-indicator {
  @extend .__dark-violet;

  &:focus {
    box-shadow: none !important;
  }
}

.__doctor-indicator {
  @extend .__green;

  &:focus {
    box-shadow: none !important;
  }
}

.__clinic-indicator {
  @extend .__red;

  &:focus {
    box-shadow: none !important;
  }
}

h1 {
  @media (min-width:375px){
    font-size: 1.5rem;
  }
}

hr {
  margin-top: .15rem;
}

.btn {
  &:focus {
    box-shadow: none!important;
  }
}

.btn-danger {
  color: #fff;
  background-color: $red;
  border-color: $red;
}
/**
MUI overloads
 */
[class|="MuiTypography"] {
 font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.MuiAccordionSummary-root .MuiAccordionSummary-expandIcon {
  font-size: 1rem;
}

.__nb:focus {
  box-shadow: none !important;
  outline: none !important;
}

.Toastify__toast--warning {
  background-color: $warning;
}
.Toastify__toast--success {
  background-color: $success;
}
.Toastify__toast--error {
  background-color: $danger;
}
.Toastify__toast--info {
  background-color: $info;
}
