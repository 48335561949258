$defaultPadding: 15px;
$messageMargin: 6px;

.chat-modal {
  $width: 320px;
  $width-md: 500px;
  padding: 15px;
  position: absolute;
  bottom: 0;
  width: $width;
  max-height: 500px;
  border: 1px solid #cacaca;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background: #f8f9fa; //bg-light bootstrap
  overflow: hidden;
  @media (min-width: 920px){
    width: $width-md;
  }

  &--header {
    height: 10%;
    border-bottom: 1px solid #cacaca;
  }

  &--body {
    height: 400px;
    overflow-y: scroll;
    margin-right: -40px;
    padding-right:40px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    position:relative;

    &::-webkit-scrollbar { /* WebKit */
      width: 0;
      height: 0;
    }

    &--footer {

    }
  }
}

.message {
  $m: $messageMargin;
  $p: calc($defaultPadding / 2);

  margin: $m 0 $m 0;
  border-radius: $p;
  padding: $p;

  &-sender {
    text-align: left;
    background: #ebebeb;
  }

  &-received {
    text-align: right;
    background: rgba(125, 240, 2, 0.3);
  }

  &-author {
    font-weight: 600;
  }

  &-text {

  }
}
