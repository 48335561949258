.panel {
  margin-left: 0.6rem;

  &-header {
    margin: 2rem 0 0.3rem 0;
  }

  &-body {
    &-buttons {
      margin-top: 0.625rem;
      width: 100%;

      button {
        margin: 0 0.6rem 0.3rem 0;
      }
    }
  }
}
